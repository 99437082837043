.middleleft {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  padding: 20px 130px;
  flex: 70%;
  order: 1;
  box-sizing: border-box;
}

.overviewHeading {
  font-size: 52px;
  color: #ff9f1c;
}

/* .overviewSlogan > span {
  background-color: #cbf3f0;
  padding: 20px;
} */

.overviewSlogan {
  margin: 30px 0;
  background-color: #cbf3f0;
  padding: 20px;
}

.image {
  width: 600px;
}

@media (max-width: 1200px) {
  .middleleft {
    flex: 100%;
    order: 2;
    padding: 50px 10px;
  }

  .image {
    width: 100%;
  }
}
