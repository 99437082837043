.middleright {
  display: flex;
  flex-direction: column;
  background-color: #ff9f1c;
  color: white;
  padding: 20px;
  border-radius: 2px;
  flex: 35%;
  order: 2;
  box-sizing: border-box;
  align-items: left;
  text-align: left;
}

.termsOfUse {
  display: flex;
  text-align: left;
  justify-content: left;
  align-items: center;
  /* align-items: left; */
}

/* .middleright > p {
  text-align: center;
} */

.drawerBox {
  width: 30vw;
  padding: 20px;
  box-sizing: border-box;
}

.middleright > h1 {
  color: #ffffff;
}

@media (max-width: 1200px) {
  .middleright {
    flex: 100%;
    order: 1;
    border-radius: 0;
  }
  .drawerBox {
    width: 100vw;
    padding: 10px;
    box-sizing: border-box;
  }
}




.buttons {
  display: flex;
  justify-content: center;
  flex: 100%;
}

.submitButton {
  margin-top: 15px;
}

.textField {
  width: 90%;
}
