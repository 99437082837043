.termsofuse {
  padding: 20px 130px;
  flex: 1;
  box-sizing: border-box;
}

@media (max-width: 1200px) {
  .termsofuse {
    padding: 50px 10px;
  }
}
