.middle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1;
  padding: 1px;
}

@media (max-width: 1200px) {
  .middle {
    padding: 0;
    flex-wrap: wrap;
  }
}
