.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.top {
  background-color: #cbf3f0;
  height: 68px;
  display: flex;
}

.top > div {
  display: flex;
  align-items: center;
}

.top > div > a {
  font-size: 20px; /* adjust as needed */
  font-family: Arial, sans-serif; /* adjust as needed */
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

.top > div > a:hover {
  text-decoration: none;
}

a {
  color: #757070;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: #333333;
  text-decoration: underline; /* This adds underlining on hover */
}

h1 {
  color: #ff9f1c;
}

h2 {
  color: #ff9f1c;
}

/* a {
  color: #ff9f1c;
  cursor: pointer;
  text-decoration: none;
} */

.bottom {
  padding: 20px;
  background-color: #cbf3f0;
  color: white;
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
}


@media (max-width: 1200px) {
  footer {
    flex-direction: column;
  }
}
